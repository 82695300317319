export const Skills = [
    "npx create-react-app new_job",
    "db.employees.insertOne({name: 'Christian'})",
    "Express",
    "Node",
    "python",
    // "C++",
    "Bootstrap",
    "react",
    // "SQL",
    "def hire_christian(salary)",
    "df = pd.read_csv('christian.csv')",
    // "NumPy",
    "git add christian.js",
    "github"

]